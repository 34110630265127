/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap'); */
:root {
  /* --primary-color:  rgb(184, 10, 10); */
  --primary-color: rgb(53, 8, 112);
  --secondary-color: rgb(255, 255, 255);
  --background-color: rgb(0, 0, 0);
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  /* background-color: rgb(0, 0, 0); */
}